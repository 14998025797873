@font-face {
	font-family: 'Titillium Web';
	font-weight: 400;
	font-style: normal;
	src: url('~@/src/fonts/titillium-web/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Titillium Web';
	font-weight: 700;
	font-style: bold;
	src: url('~@/src/fonts/titillium-web/TitilliumWeb-Bold.ttf') format('truetype');
}
